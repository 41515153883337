<template>
  <div class="well well-lg registration">
    <div class="row">
        <div class="col-sm-offset-4 col-sm-4">
            <form class="form-horizontal" @submit.prevent="submit">

                <div class="text-center">
                    <h2>{{ $t('forgot-password.forgot-password') }}</h2>
                    <p>{{ $t('forgot-password.forgot-password-message') }}</p>
                    <div v-if="error">
                        <div class="invalid-feedback">
                            <div>{{ $t('forgot-password.forgot-password-error') }}</div>
                        </div>
                    </div>
                </div>

                <br>

                <div class="form-group">
                    <label class="" for="email">{{ $t('register.phone-number') }}:</label>
                    <vue-tel-input 
                      v-model="user.phone"
                      :defaultCountry="geo.country_code2"
                      mode="international"
                      :inputOptions="options"
                    ></vue-tel-input>         
                </div>

                <div class="form-group" v-if="!delay">  
                  <button type="submit" class="btn btn-default">{{ $t('forgot-password.reset') }}</button>
                </div>

            </form>
        </div>
    </div>
</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import AuthService from "@/services/auth";
import { mapGetters } from "vuex";

export default {
  name: 'forgot-password',
  data() {
    return {
        user: {
          phone: ''
        },
        options: {
          placeholder: this.$i18n.t('header.place_phone')
        },
        error:'',
        delay: false
    }
  },
  validations: {
    user: {
      phone: {
          required,
      }
    }
  },
  methods:{
    submit: function() {
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }

        let str = this.user.phone;
        str = str.replace(/\s/g, "");
        this.user.phone = str.replace(/\+/g, "");
        
        AuthService.forgot_password(this.user)
        .then( res => {
            if(res.success === false) {
              this.error = true;
            } else {
              this.error = false;
              this.$router.push("/reset-password");
            }
        })
        .catch( err => console.log(err) )
    }
  },
  computed: {
    ...mapGetters({
        geo:'geo',
    })
  },
}
</script>

<style>
  .well .btn {
    margin-left: 0px;
  }
  .registration .vti__input {
    color : var(--bg-dark-medium)
  }
  .registration .vti__dropdown-item {
    color : var(--bg-dark-medium)
  }
  .registration .vti__dropdown {
    background-color: var(--a-text);
  }
</style>